<template>
  <center-box>
    <div class="neo-card">
      <v-form ref="loginForm" v-model="valid" lazy-validation>
        <h2 class="heading-size-4 mb-4">{{ $t("logIn") }}</h2>
        <div class="neo-text-field">
          <div>{{ $t("email") }}</div>
          <v-text-field
            v-model="loginEmail"
            flat
            solo
            outlined
            dense
            :rules="[rules.required, rules.email]"
            :label="$t('email')"
            required
            @keydown.enter="handleEnter"
            autocomplete="username"
            @keyup="clearError"
            :messages="errorMsgEmail"
          ></v-text-field>
        </div>

        <div class="neo-text-field">
          <div>{{ $t("password") }}</div>
          <v-text-field
            v-model="loginPassword"
            flat
            solo
            outlined
            dense
            :append-icon="showPwd ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPwd ? 'text' : 'password'"
            :rules="[rules.required, rules.error]"
            name="input-10-1"
            :label="$t('password')"
            @click:append="toggleEye"
            @keydown.enter="handleEnter"
            @keyup="clearError"
            autocomplete="password"
            :messages="errorMsgPwd"
          ></v-text-field>
        </div>

        <div class="text-center">
          <button
            class="forgot-password-btn"
            @click="showForgotDialog"
            type="button"
          >
            {{ $t("forgotPassword") }}
          </button>
        </div>

        <v-btn
          ref="loginBtn"
          id="sign-in-button"
          class="mt-4"
          block
          :disabled="waiting || !valid"
          color="primary"
          @click="validate"
        >
          {{ $t("logIn") }}
          <v-icon size="32" v-if="waiting" class="ml-1 waiting">
            mdi-tire
          </v-icon>
        </v-btn>
        <!-- <p class="reCAPTCHA-disclaimer text-center">
        This site is protected by reCAPTCHA and the Google
        <a target="_blank" href="https://policies.google.com/privacy"
          >Privacy Policy</a
        >
        and
        <a target="_blank" href="https://policies.google.com/terms"
          >Terms of Service</a
        >
        apply.
      </p> -->
        <div class="recaptcha-badge">
          <VueRecaptcha
            style="display: inline-block"
            :sitekey="recaptchaKey"
            @verify="onVerify"
            @expired="onExpired"
            ref="recaptcha"
            size="invisible"
            badge="inline"
            type="contact"
            :loadRecaptchaScript="false"
            theme="light"
          ></VueRecaptcha>
        </div>
      </v-form>
      <login-thirds
        @loginThirdClick="loginThirdClick($event)"
        :login-thirds="loginThirds"
        :inter-title="$t('noAccount')"
        path="invite"
        :link-title="$t('register')"
      ></login-thirds>
    </div>
    <!-- <div ref="recaptcha-container" id="recaptcha-container"></div> -->

    <NeoModal
      v-if="forgotDialog"
      :max-width="400"
      :title-header="$t('forgotPassword')"
      @close="cancelForgot"
    >
      <v-card-text>
        <p class="text-center mt-6">
          {{ $t("resetEmail") }}
        </p>
        <v-text-field
          v-model="forgotEmail"
          placeholder="email@example.com"
          flat
          solo
          outlined
          dense
          type="email"
        ></v-text-field>
      </v-card-text>
      <template slot="actions">
        <v-btn @click="cancelForgot" class="mr-6" outlined color="primary">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="sendEmail(forgotEmail)">{{
          $t("send")
        }}</v-btn>
      </template>
    </NeoModal>
  </center-box>
</template>

<script>
// import firebase from 'firebase/compat/app';
import CenterBox from "@/components/Templates/CenterBox";
import LoginThirds from "@/components/Organisms/LoginThirds";
import { firebase, analytics } from "@/helpers/firebase";
import NeoModal from "@/components/Molecules/NeoModal";
import { VueRecaptcha } from "vue-recaptcha";

// console.log(r);

export default {
  name: "LoginPage",
  components: {
    CenterBox,
    LoginThirds,
    NeoModal,
    VueRecaptcha,
  },
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE,
      disabled: false,
      errorMsgPwd: "",
      errorMsgEmail: "",
      manualRecaptcha: undefined,
      waiting: false,
      recaptchaOk: false,
      valid: true,
      showPwd: false,
      loginPassword: "",
      loginEmail: "",
      rules: {
        error: () => true || "",
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 6) || "Min 9 characters",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        number: (v) =>
          Number.isInteger(Number(v)) || "Invalid telephone number",
      },
      loginThirds: [
        { icon: "mdi-google", color: "#4285f4", id: "google" },
        { icon: "mdi-twitter", color: "#1d9bf0", id: "Twitter" },
        { icon: "mdi-facebook", color: "#3b5998", id: "Facebook" },
      ],
      forgotDialog: false,
      forgotEmail: null,
    };
  },

  methods: {
    onVerify(response) {
      if (response !== "") {
        this.login();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    clearError() {
      this.errorMsgPwd = "";
      this.errorMsgEmail = "";
      this.rules.error = true;
    },
    toggleEye() {
      this.showPwd = !this.showPwd;
    },
    showEmailVerificationMessage() {
      if (this.$store.state.user && !this.$store.state.user.emailVerified) {
        // store.dispatch('showAlertMessage');
        this.$store.commit("ALERT_MESSAGE", {
          msg: "Please verify your email",
          action: "sendEmailVerification",
          actionMsg: "resend email",
        });
      }
    },
    handleEnter() {
      this.$refs.loginBtn.$el.click();
    },
    login() {
      this.waiting = true;
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.

          firebase
            .auth()
            .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
            .then(() => {
              analytics.logEvent("login", { method: "email" });
              this.$store.commit("loggedIn", { redirect: true });
            })
            .catch((error) => {
              if (error.code === "auth/too-many-requests") {
                this.errorMsgEmail = "Account blocked";
                this.errorMsgPwd = "try again later or reset your password.";
              } else {
                this.errorMsgPwd = "wrong email or password";
              }
              this.rules.error = false;
              this.waiting = false;
            });
        })
        .catch(() => {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // console.log(error, errorCode, errorMessage);
          this.waiting = false;
        });
    },
    validate() {
      if (this.$refs.loginForm.validate()) this.$refs.recaptcha.execute();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    loginProvider(value) {
      let provider;
      if (value === "google") {
        provider = new firebase.auth.GoogleAuthProvider();
      } else if (value === "twitter") {
        provider = new firebase.auth.TwitterAuthProvider();
      } else if (value === "facebook") {
        provider = new firebase.auth.FacebookAuthProvider();
      }

      firebase.auth().useDeviceLanguage();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          // const credential = result.credential;
          const {
            additionalUserInfo: { isNewUser },
          } = result;

          if (isNewUser) {
            result.user.delete().then(()=> {
              this.$router.push(`/${this.$store.state.lang}/invite`);
            })
            
          } else {
            analytics.logEvent("login", { method: value });

            this.$store.commit("loggedIn", { redirect: true });
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          if (errorCode === "auth/account-exists-with-different-credential") {
            this.$store.dispatch("showAlertMessage");
            this.$store.commit(
              "ALERT_MESSAGE",
              "The email linked to this account is already registered"
            );
          }
          // else {
          //   const errorMessage = error.message;
          //   console.log(errorMessage);
          //   // The email of the user's account used.
          //   // const email = error.email;
          //   // The firebase.auth.AuthCredential type that was used.
          // }
        });
    },
    loginThirdClick(value) {
      this.loginProvider(value.id.toLowerCase());
    },
    showForgotDialog() {
      this.forgotDialog = true;
    },
    cancelForgot() {
      this.forgotDialog = false;
    },
    sendEmail(email) {
      this.forgotDialog = false;
      this.$store.commit("sendPasswordReset", email);
      this.forgotEmail = null;
    },
  },
  mounted() {
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "sign-in-button",
    //   {
    //     size: "invisible",
    //     callback: () => {
    //       this.recaptchaOk = true;
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       // this.validate();
    //       // ...
    //     },
    //     "expired-callback": () => {
    //       // Response expired. Ask user to solve reCAPTCHA again.
    //       console.log("bad recaptcha");
    //       // ...
    //     },
    //   }
    // );
    // window.recaptchaVerifier.render();
    // window.recaptchaVerifier.verify();
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-btn {
  font-weight: bold;
  font-size: var(--font-size-super-small);
  &:hover {
    color: var(--color-primary);
  }
}
.recaptcha-badge {
  margin-top: 20px;
  text-align: center;
}
// .grecaptcha-badge {
  // visibility: hidden;
// }
.reCAPTCHA-disclaimer {
  font-size: var(--font-size-super-small);
}
</style>
